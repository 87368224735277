<template>
    <q-page class=" q-pa-lg">
        <!-- DIALOG PARA REASIGNAR -->
        <q-dialog v-model="dialogAsinga" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-toolbar>
                    <q-toolbar-title><span class="text-weight-bold">Reasignar Asiento</span></q-toolbar-title>
                </q-toolbar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12" v-if="banderaPue">
                            <q-input v-model="fechaRMasked" filled label="Fecha Pago" class="q-mr-sm" name="event"
                                outlined dense>
                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="fechaR" @input="UltimoDiaMes">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Ok" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-input>
                        </div>
                        <div class="col-12">
                            <q-select dense filled label="Número de Cuenta" v-model="selectCuenta" option-label="cuenta"
                                @filter="filtroCatalogoCuenta" :options="filtroCatalogoCuentas" use-input
                                input-debounce="0">
                            </q-select>
                        </div>
                        <div class="col-12" v-if="banderaPue">
                            <q-input dense filled label="Referencia" v-model="descripcionBanco"></q-input>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat label="Cancelar" color="primary" v-close-popup />
                    <q-btn v-if="banderaPue" flat label="Aplicar Cambios PUE" color="primary" @click="PutAsientos()" />
                    <q-btn v-if="!banderaPue" flat label="Aplicar Cambios PPD" color="primary"
                        @click="PutAsientosPPD()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOGO PARA CREAR UNA NUEVA POLIZA -->
        <q-dialog full-width v-model="dialogNuevaPoliza" persistent transition-show="scale" transition-hide="scale">
            <Poliza @cierraVentana="dialogNuevaPoliza = false"></Poliza>
        </q-dialog>

        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-5">
                <div class=" text-h5">Bancos</div>
            </div>
            <!-- TIPO DE MOVIMIENTO -->
            <div class="col-12 col-md-2">
                <q-select filled dense v-model="selectedTipo" :options="itemsTipo"
                    label="Tipo de Movimiento"></q-select>
            </div>
            <!-- FECHA INICIAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fehaIMasked" filled label="Fecha Inicial" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaI" @input="UltimoDiaMes">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>
            <!-- FECHA FINAL -->
            <div class="col-12 col-md-3">
                <q-input v-model="fechaFMasked" filled label="Fecha Final" name="event" outlined dense>

                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaF">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>

                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify" @click="GetConciliacion">
                            <q-tooltip>
                                Buscar
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-swap-horizontal" @click="OpenDialogAsigna">
                            <q-tooltip>
                                Reasignar Movimientos
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-broom" @click="Limpiar">
                            <q-tooltip>
                                Limpiar
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-bank-transfer" @click="GetReporte()">
                            <q-tooltip>
                                Reporte Bancos
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-input>
            </div>
        </div>

        <!-- TABLA DE MOVIMIENTOS -->
        <q-table class="shadow-0 header-tabla" :filter="filter" :pagination="initialPagination" :loading="loadingTabla"
            :columns="columns" row-key="_id" :data="itemsMovimientos" selection="multiple"
            :selected.sync="selectedMovimientos">

            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>

            <template v-slot:top-left>
                Seleccionados: {{ formatCurrency(sumSeleccionados) }}
            </template>

            <template v-slot:top-right>
                <q-input filled dense v-model="filter" placeholder="Filtrar">
                    <template v-slot:append>
                        <q-icon name="search" />
                    </template>
                </q-input>
            </template>

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width><q-checkbox v-model="props.selected"></q-checkbox></q-td>
                    <q-td auto-width key="acciones">
                    </q-td>
                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                    <q-td key="tipo" :props="props">{{ props.row.tipo }}</q-td>
                    <q-td key="numPoliza" :props="props">{{ props.row.numPoliza }}</q-td>
                    <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                    <q-td key="metodoPago" :props="props">{{ props.row.metodoPago }}</q-td>
                    <q-td key="formaPago" :props="props">{{ props.row.formaPago }}</q-td>
                    <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
                    <q-td key="folio" :props="props">{{ props.row.folio }}</q-td>
                    <q-td key="folioFiscal" :props="props">{{ props.row.folioFiscal }}</q-td>
                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>

<script>
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import Poliza from './ConciliarPoliza.vue'
import { ReporteMovBancosPdf } from '../PDF/ReporteMovBancosPdf.js'

export default {
    components: {
        Poliza
    },
    data() {
        return {
            selectedTipo: 'Pagos',
            itemsTipo: ['Pagos', 'Cobros', 'Nómina', 'Pagos Caja y Efectivo', 'Cobros Caja y Efectivo'],

            filter: '',

            fechaI: new Date(),
            fechaF: new Date(),
            fechaR: new Date(),

            loadingTabla: false,
            initialPagination: {
                page: 1,
                rowsPerPage: 10
            },

            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: false },
                { name: 'importe', align: 'right', label: 'Importe', field: 'importe', sortable: true, },
                { name: 'tipo', align: 'left', label: 'Tipo', field: 'tipo', sortable: true },
                { name: 'numPoliza', align: 'left', label: 'Num. Póliza', field: 'numPoliza', sortable: true },
                { name: 'fecha', align: 'left', label: 'Fecha', field: 'fecha', sortable: true },
                { name: 'rfc', align: 'left', label: 'RFC', field: 'rfc', sortable: true },
                { name: 'nombre', align: 'left', label: 'Nombre', field: 'nombre', sortable: true, },
                { name: 'metodoPago', align: 'left', label: 'Metodo de Pago', field: 'metodoPago', sortable: true, },
                { name: 'formaPago', align: 'left', label: 'Forma de Pago', field: 'formaPago', sortable: true, },
                { name: 'serie', align: 'left', label: 'Serie', field: 'serie', sortable: true, },
                { name: 'folio', align: 'left', label: 'Folio', field: 'folio', sortable: true, },
                { name: 'folioFiscal', align: 'left', label: 'Folio Fiscal', field: 'folioFiscal', sortable: true, },
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
            ],

            itemsMovimientos: [],
            selectedMovimientos: [],
            dialogAsinga: false,
            selectCuenta: null,
            filtroCatalogoCuentas: null,
            dialogNuevaPoliza: false,

            itemsCatalogoCuentas: [],

            descripcionBanco: '',

            banderaPue: false,
        }
    },

    computed: {
        token() {
            return this.$store.state.usuario;
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },

        fechaRMasked() {
            moment.locale('es-mx');
            return this.fechaR ? moment(this.fechaR).format('DD/MMMM/yyyy') : ''
        },

        sumSeleccionados() {
            return this.sum(this.selectedMovimientos, 'importe');
        },

        empresa() {
            return this.$store.state.empresaStore;
        },

    },

    created() {
        this.getCatalogoCuentas();
    },

    methods: {
        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },

        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async GetConciliacion() {
            let tipo = this.selectedTipo;
            if (tipo === 'Pagos' || tipo === 'Cobros' || tipo === 'Nómina') {
                this.GetConciliacionBancos();
            } else {
                this.GetConciliacionEfectivo();
            }
        },

        async GetConciliacionBancos() {
            this.itemsMovimientos = [];
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando movimientos...', messageColor: 'white' });
            try {
                let tipo = '';
                if (this.selectedTipo === 'Pagos') {
                    tipo = "Egreso";
                } else if (this.selectedTipo === "Cobros") {
                    tipo = "Ingreso";
                } else if (this.selectedTipo === 'Nómina') {
                    tipo = "Nomina";
                }
                let fechaI = new Date(this.fechaI);
                let fechaF = new Date(this.fechaF);
                fechaI = format(fechaI, 'yyyy-MM-dd');
                fechaF = format(fechaF, 'yyyy-MM-dd');
                let response = await axios.get('Polizas/GetConciliacionAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF + '/' + tipo);
                // console.log(response.data);
                this.itemsMovimientos = response.data
                this.$q.loading.hide()
            } catch (error) {
                this.$q.loading.hide()
            }
        },

        async GetConciliacionEfectivo() {
            this.itemsMovimientos = [];
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando movimientos...', messageColor: 'white' });
            try {
                let tipo = '';
                if (this.selectedTipo === 'Pagos Caja y Efectivo') {
                    tipo = "Egreso";
                } else if (this.selectedTipo === "Cobros Caja y Efectivo") {
                    tipo = "Ingreso";
                }
                let fechaI = new Date(this.fechaI);
                let fechaF = new Date(this.fechaF);
                fechaI = format(fechaI, 'yyyy-MM-dd');
                fechaF = format(fechaF, 'yyyy-MM-dd');
                let response = await axios.get('Polizas/GetConciliacionCajaAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF + '/' + tipo);
                // console.log(response.data);
                this.itemsMovimientos = response.data
                this.$q.loading.hide()
            } catch (error) {
                this.$q.loading.hide()
            }
        },

        Limpiar() {
            this.selectedMovimientos = [];
        },

        OpenDialogAsigna() {
            const lista = [...this.selectedMovimientos]

            //VALIDAMOS QUE TENGA AL MENOS UN COMPROBANTE SELECCIONADO
            if (lista.length == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Seleccione al menos un movimiento',
                    actions: [{ label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }

            //VALIDAMOS QUE NO TENGA METODOS DE PAGO COMBINADOS
            const metodosUnicos = [...new Set(lista.map(item => item.metodoPago))];
            if (metodosUnicos.length != 1) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Sólo se puden seleccionar comprobantes con el mismo método de pago',
                    actions: [{ label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }

            //VALIDAMOS SI EL METODO ES PU
            if (metodosUnicos[0] === "PUE | Pago en una sola exhibición") {
                this.banderaPue = true;
                this.fechaR = lista[0].fecha;
            } else {
                this.banderaPue = false;
            }
            this.descripcionBanco = '';
            this.dialogAsinga = true;
        },

        async PutAsientos() {
            if (!this.selectCuenta) {
                this.$q.notify({
                    type: 'warning', message: 'Seleccione la cuenta para continuar.', actions: [{ label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }

            if (this.descripcionBanco == '') {
                this.$q.notify({
                    type: 'warning', message: 'Escriba una referencia.', actions: [{ label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Generando cambios...', messageColor: 'white' })
            try {
                // console.log(this.selectedMovimientos);
                for (let a of this.selectedMovimientos) {
                    const databaseName = 'erp_' + this.token.rfc;
                    const _id = a._id;
                    const indice = a.item;
                    let cuenta = this.selectCuenta;
                    cuenta.descripcionAsiento = this.descripcionBanco;

                    const fechaFormateada = this.fechaR.replace(/\//g, '-');
                    const fechaFormateadaOriginal = a.fecha.replace(/\//g, '-');
                    let response = await axios.put("Polizas/PutAsientoAsync/" + databaseName + '/' + _id + '/' + indice + '/' + fechaFormateada + '/' + fechaFormateadaOriginal + '/' + a.tipo, cuenta);

                    let indiceSplice = this.itemsMovimientos.findIndex(f => f._id == _id);
                    this.itemsMovimientos.splice(indiceSplice, 1);
                }

                this.dialogAsinga = false;
                this.selectedMovimientos = [];
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        async PutAsientosPPD() {
            if (!this.selectCuenta) {
                this.$q.notify({
                    type: 'warning', message: 'Seleccione la cuenta para continuar.', actions: [{ label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Generando cambios...', messageColor: 'white' })
            try {
                // console.log(this.selectedMovimientos);
                for (let a of this.selectedMovimientos) {
                    const databaseName = 'erp_' + this.token.rfc;
                    const _id = a._id;
                    const indice = a.item;
                    let cuenta = this.selectCuenta;
                    cuenta.descripcionAsiento = "";

                    const fechaFormateadaOriginal = a.fecha.replace(/\//g, '-');
                    let response = await axios.put("Polizas/PutAsientoAsync/" + databaseName + '/' + _id + '/' + indice + '/' + fechaFormateadaOriginal + '/' + fechaFormateadaOriginal + '/' + a.tipo, cuenta);

                    let indiceSplice = this.itemsMovimientos.findIndex(f => f._id == _id);
                    this.itemsMovimientos.splice(indiceSplice, 1);
                }

                this.dialogAsinga = false;
                this.selectedMovimientos = [];
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        filtroCatalogoCuenta(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.filtroCatalogoCuentas = this.itemsCatalogoCuentas.filter(v => v.cuenta.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.filtroCatalogoCuentas.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                }
            )
        },

        async getCatalogoCuentas() {
            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando catálogo', messageColor: 'white' })
                let response = await axios.get("CatalogoCuentas/GetCatalogoCuentas/erp_" + this.token.rfc);
                let catalogo = response.data.filter(f => f.tipoCuenta === 'R' && f.estatus === 'Vigente' && f.numCuentaUnico.startsWith('0000000102'))
                let deudores = response.data.filter(f => f.numCuentaUnico === '0000000107.0000000005')
                let acreedores = response.data.filter(f => f.numCuentaUnico === '0000000205.0000000006')
                let caja = response.data.filter(f => f.tipoCuenta === 'R' && f.estatus === 'Vigente' && f.numCuentaUnico.startsWith('0000000101'))
                catalogo.push(...deudores)
                catalogo.push(...acreedores)
                if (caja.length > 1) {
                    let caja_ = [...caja];
                    catalogo.push(...caja_)
                }
                let a = catalogo.shift();
                this.itemsCatalogoCuentas = catalogo;
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        async GetPoliza(id) {
            try {
                let response = await axios.get("Polizas/GetPolizas/erp_" + this.token.rfc + '/' + id);
                // console.log(response.data)
                let x = response.data;
                return x;
            } catch (error) {
                console.log(error)
            }
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        //REPORTE DE LOS MOVIMIENTOS BANCARIOS
        async GetReporte() {
            try {
                let fechaI = new Date(this.fechaI);
                let fechaF = new Date(this.fechaF);
                fechaI = format(fechaI, 'yyyy-MM-dd');
                fechaF = format(fechaF, 'yyyy-MM-dd');
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando catálogo', messageColor: 'white' })
                let response = await axios.get('Reportes/GetMovimientosBancariosAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF);
                console.log(response)
                await this.ExportaPdf(response.data);
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()

            }
        },

        async ExportaPdf(items) {
            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);
            const pdfBase64 = await ReporteMovBancosPdf(items, this.empresa, fechaI, fechaF, this.empresa.color);
            const fileName = 'Movimientos bancarios del ' + this.FormatoFechaSinHora(fechaI) + '_al_' + this.FormatoFechaSinHora(fechaF) + ".pdf";
            const link = document.createElement("a");
            link.href = pdfBase64;
            link.download = fileName;
            link.click();
        },

        FormatoFechaSinHora(value) {
            const fecha = new Date(value);
            const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
            return fechaFormateada.toUpperCase();
        },
    }
}
</script>